import React from "react";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import BannerWeb from "../components/BannerWeb";

const LandingWeb = (props) => {
  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://brandinavian.com/#organization",
        name: "Brandinavian",
        url: "https://brandinavian.com/",
        sameAs: [
          "https://www.facebook.com/brandinavian/",
          "https://www.instagram.com/brandinavian/",
          "https://www.linkedin.com/company/brandinavian/",
          "https://twitter.com/brandinavian",
        ],
        logo: {
          "@type": "ImageObject",
          "@id": "https://brandinavian.com/#logo",
          inLanguage: "en-US",
          url:
            "https://cdn.brandinavian.com/wp-content/uploads/2020/09/brandinavian-logo.png",
          width: 1112,
          height: 116,
          caption: "Brandinavian | Modern Web Development",
        },
        image: { "@id": "https://brandinavian.com/#logo" },
      },
      {
        "@type": "WebSite",
        "@id": "https://brandinavian.com/#website",
        url: "https://brandinavian.com/",
        name: "Brandinavian",
        description: "USA Web Developer",
        publisher: { "@id": "https://brandinavian.com/#organization" },
        inLanguage: "en-US",
      },
      {
        "@type": "WebPage",
        "@id": "https://brandinavian.com/web-development/#webpage",
        url: "https://brandinavian.com/web-development/",
        name: "Web development | Brandinavian",
        isPartOf: { "@id": "https://brandinavian.com/#website" },
        description:
          "Get a best-in-class website, cut from the cloth of Facebook. Get started with Brandinavian today!",
        breadcrumb: {
          "@id": "https://brandinavian.com/web-development/#breadcrumb",
        },
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://brandinavian.com/web-development"],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://brandinavian.com/web-development/#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/",
              url: "https://brandinavian.com/",
              name: "Web developer",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/web-development",
              url: "https://brandinavian.com/web-development",
              name: "Modern web development",
            },
          },
        ],
      },
    ],
  };
  return (
    <Layout>
      <SEO
        lang="en-US"
        title="Web development | Brandinavian"
        description="Get a best-in-class website, cut from the cloth of Facebook."
        schemaMarkup={schema}
      />
      <BannerWeb />
      <div id="main" className="alt darkmode">
        <section className="pre-article dark">
          <li className="tag">Modern web development</li>
        </section>
        <section id="one" className="article-section main-service">
          <div className="inner article service-article">
            <header className="major">
              <h2>The challenge</h2>
            </header>
            <p>
              A vast majority of websites that exist on the internet in
              today's {new Date().getFullYear()}, still rely on technologies
              that were initially invented in the 90's and early 00's.
              Technologies such as the programming language{" "}
              <a
                href="https://www.php.net/manual/en/intro-whatis.php"
                target="_blank"
                rel="noopener"
                rel="noreferrer"
              >
                PHP
              </a>{" "}
              and the JavaScript framework{" "}
              <a
                href="https://www.w3schools.com/whatis/whatis_jquery.asp"
                target="_blank"
                rel="noopener"
                rel="noreferrer"
              >
                jQuery
              </a>
              . A testiment to this, is the very popular (and primarily PHP
              based) content management system{" "}
              <a
                href="https://wordpress.org/about/"
                target="_blank"
                rel="noopener"
                rel="noreferrer"
              >
                WordPress
              </a>
              , of which an estimated <b>75 million</b> websites utilize. <br />
              <br /> <b>That is 37.6% of all websites.</b>
              <br />
              <br /> Web technology, however, has evolved quite a bit in the
              last 25 years, and we now possess tools and technologies that can
              enable exponentially faster, safer and much more seamless browsing
              experiences.
            </p>
            <div className="dark-mode-image red">
              <Img fluid={props.data.imageOne.childImageSharp.fluid} alt="" />
              <li className="tag branded">BRANDINAVIAN</li>
            </div>
            <header className="major">
              <h2>Servers have it hard</h2>
            </header>
            <p>
              So.. Are systems like WordPress really a problem? The answer is
              both yes and no. The problem with using a content management
              system (CMS) directly on your server - to render your website -
              is, that{" "}
              <a
                href="https://www.sitepoint.com/how-php-executes-from-source-code-to-render/"
                target="_blank"
                rel="noopener"
                rel="noreferrer"
              >
                they actually depend on the horsepower of your server
              </a>{" "}
              to display your content. How is that? Well, in most cases, your
              content doesen't actually live directly on your server. When you
              create new content in WordPress (Or Drupal, Joomla, Concrete5
              etc.), it is typically stored in a database. A database that this
              content is gotten from, <b>every time</b> a user wants to visit a
              page on your website.
              <br />
              <br /> This task of constantly having to fetch information to
              dynamically construct every page, ends up being detrimental to the
              speed of your website - and because of bottlenecks like these, it
              has become a common axiom over the years, that WordPress websites
              tend to be "slow".{" "}
              <a
                href="https://developers.google.com/web/updates/2018/07/search-ads-speed"
                target="_blank"
                rel="noopener"
                rel="noreferrer"
              >
                And in the eyes of most search engines
              </a>
              , they quite often load for an unacceptable amount of time. Of
              course it is hard to generalize this across all CMS - but the idea
              of these websites being generally 'less than performant' is valid
              enough.
            </p>
            <div className="dark-mode-image yellow">
              <Img fluid={props.data.imageTwo.childImageSharp.fluid} alt="" />
              <li className="tag branded">BRANDINAVIAN</li>
            </div>
            <header className="major">
              <h2>The solution?</h2>
            </header>
            <p>
              So, should we stop using WordPress? No, we definitely shouldn't.
              In fact, WordPress is one of the best and most customizable
              systems for organizing website content out there. We should,
              however, stop using it <b>directly</b> on our servers to display
              our websites. Actually, we can completely{" "}
              <b>stop relying on servers</b> to display our websites.
              <br />
              <br /> We can seperate, what is called the "Front-end" (or the{" "}
              <i>face</i>) of our website from WordPress - and instead use
              powerful{" "}
              <a
                href="https://jamstack.org/"
                target="_blank"
                rel="noopener"
                rel="noreferrer"
              >
                JAMstack technologies
              </a>
              , that have the ability to take all our data from WordPress, and
              create static files that we can use as our front-end. Files that
              require an order of magnitude less horsepower to display. Such a
              small amount, that we don't need the server anymore. We can serve
              them directly from a{" "}
              <a
                href="https://en.wikipedia.org/wiki/Content_delivery_network"
                target="_blank"
                rel="noopener"
                rel="noreferrer"
              >
                CDN
              </a>
              .
              <br />
              <br /> I use the powerhouse of a framework that Facebook is built
              on, called <b>React</b>, which I leverage with a static site
              generator to build some of the fastest websites possible. <b>Websites
              that are to the average WordPress website, what a rocket engine
              would be to an 89' Caprice Classic</b>.
              <br />
              <br />
              The proof is in the pudding. The application that you are
              currently browsing is built with these technologies. Go ahead;
              Performance-test my website{" "}
              <a href="https://gtmetrix.com" target="_blank">
                here
              </a>
              .
            </p>
            <div className="dark-mode-image blue">
              <Img fluid={props.data.imageThree.childImageSharp.fluid} alt="" />
              <li className="tag branded">BRANDINAVIAN</li>
            </div>
            <header className="major">
              <h2>One small step for you</h2>
            </header>
            <p>
              One <b>giant</b> leap for your presence on the internet.
              <br />
              <br /> If you'd like to learn more about how these technologies
              actually work, and why these types of solutions are impressively
              scalable - I encourage you to get in touch with me. What I can
              build for you, is a next-generation website, that will outperform
              a vast amount of the 37.6% I mentioned earlier.
              <br />
              <br />
              If you use a content management system like WordPress currently,
              don't be afraid to approach me either. You can continue doing so.{" "}
              <b>I specialize in creating React applications</b> that fetch
              information from systems like these. This is what we call a{" "}
              <a
                href="https://en.wikipedia.org/wiki/Headless_content_management_system"
                target="_blank"
                rel="noopener"
                rel="noreferrer"
              >
                Headless CMS solution
              </a>
              . A much <b>faster</b>, and <b>safer</b> way to have a website in{" "}
              {new Date().getFullYear()}.
            </p>
            <ul className="actions align-center">
              <li>
                <Link to="/contact" className="button">
                  Start building today!
                </Link>
              </li>
            </ul>
          </div>
          <div className="inner article about-author">
            <div className="author-image-box">
              <Img
                className="large-author-image"
                fluid={props.data.imageFour.childImageSharp.fluid}
              />
            </div>
            <div className="author-information">
              <header className="major special">
                <h2 className="large-author-name">Hi, I'm Mads</h2>
              </header>
              <p className="author-biography">
                I'm a Full Stack Engineer and Media Science graduate. Through
                Brandinavian, I help awesome people all over the world,
                designing and engineering digital solutions. I build
                best-in-class, scalable web applications, that convert and
                communicate well.
              </p>
              <p className="author-links" />
              <li className="tag">
                <Link to="/contact">Get in touch</Link>
              </li>
              <li className="tag">
                <Link to="/contact">Request Resume</Link>
              </li>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default LandingWeb;

export const webDevQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "server-hdd.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "rocketsedan.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "space-astro.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFour: file(relativePath: { eq: "creative-author.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
