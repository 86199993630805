import React from "react";
import * as THREE from "three";
import FOG from "vanta/dist/vanta.fog.min";

class BannerWeb extends React.Component {
  constructor(props) {
    super(props);
    this.vantaRef = React.createRef();
    this.zoomLevel = 1.0;
      if (typeof window !== `undefined`) {
      const mediaQuery = window.matchMedia('(max-width: 1500px)')
      if (mediaQuery.matches) {
        this.zoomLevel = 0.5;
      } else {
        this.zoomLevel = 1.0;
      }
    }
  }
  componentDidMount() {
    this.vantaEffect = FOG({
      el: this.vantaRef.current,
      THREE: THREE,
      highlightColor: 0xf0ff,
      baseColor: 0x0,
      zoom: this.zoomLevel
    });
  }
  componentWillUnmount() {
    if (this.vantaEffect) this.vantaEffect.destroy();
  }
  render() {
    return (
      <section id="banner" className="style2 land-banner" ref={this.vantaRef}>
        <div className="inner">
          <header className="major">
            <h1>Web development</h1>
          </header>
          <div className="content">
            <p>
              Best-in-class websites,
              <br />
              cut from the cloth of Facebook.
            </p>
          </div>
        </div>
      </section>
    );
  }
}
export default BannerWeb;
